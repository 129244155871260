.error-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding-top: 20px !important;

  h2 {
    text-align: center;
    margin-bottom: 14px;
  }

  &__description {
    flex: 0 calc(50% - 7px);

    @include query(800px) {
      flex: 1;
    }

    &__title {
      display: block;
      font-size: 32px;
      margin-bottom: 14px;

      &__error-code {
        display: block;
        font-size: 18px;
        margin-bottom: 7px;
      }
    }

    &__text {
      font-size: 14px;
    }
  }

  &__help {
    flex: 0 calc(50% - 7px);
    border: 1px solid #dfdfdf;
    font-size: 12px;
    padding: 21px;

    h2 {
      font-size: 18px;
      text-align: left;
    }

    @include query(800px) {
      display: none;
    }

    a {
      color: #407b32;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    ul {
      margin: 0 !important;

      li {
        list-style-type: square;

        &::marker {
          color: #407b32;
        }
      }
    }
  }

  &__categories {
    flex: 0 100%;
    margin-bottom: 20px;
    margin-top: 27px;

    &__others {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 14px;

      &__title {
        margin: 14px 0;
        background: #ececec;
        padding: 4px;
        font-size: 12px;
        border-radius: 4px;
      }
    }

    &__need-help-button {
      display: inline-flex;
      padding: 4px 14px 4px 4px;
      border-radius: 4px;
      background: #dfdfdf;
      color: black;

      align-self: center;

      &__icon {
        width: 40px;
        height: 40px;
        flex: 0 calc(40px);
        background: #7a7a7a;
        border-radius: 4px;
        line-height: 40px;
        color: white;
        text-align: center;
        font-size: 27px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        margin-left: 7px;

        span {
          font-size: 10px;
          display: block;
          margin-bottom: 6px;
        }
      }

      &:hover {
        text-decoration: none;
        background: #d1cece;
      }
    }
  }

  &__top-products {
    flex: 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}