.subcategories {
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-wrap: wrap;
    //padding-bottom: 30px;
    margin-bottom: 30px;
    overflow-x: auto;

    &--item {
        display: flex;
        align-items: center;
       // padding: 10px 15px;
        margin-right: 17px;
      //  min-width: 200px;
        flex: 1;
        flex-grow: 0;
        flex-direction: column;

        height: 100%;

        &:last-of-type {
            margin-right: 0;
        }

        span.name {
            display: flex;
            height: 37px;
            align-items: center;
            margin-top: 9px;
            font-size: 14px;
            font-weight: 300;
            color: #000;
            text-align: center;
            line-height: 1.3;
        }

        img {
            //margin-right: 15px;
            //max-width: 50%;
            //width: 30%;
            //height: auto;
            border-radius: $radius;
           // aspect-ratio: 157 / 167;
            //width: 157px;
        }
    }

    @include query($grid-l) {
        padding: 0 15px 15px 15px;

        &--item {

            img {
                width: 80px;
                aspect-ratio: 80 / 85.09;
            }
        }
    }

    @include query($grid-s) {
        justify-content: flex-start;
    }
}
