/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.error-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px !important;
}
.error-page h2 {
  text-align: center;
  margin-bottom: 14px;
}
.error-page__description {
  flex: 0 calc(50% - 7px);
}
@media screen and (max-width: 50em) {
  .error-page__description {
    flex: 1;
  }
}
.error-page__description__title {
  display: block;
  font-size: 32px;
  margin-bottom: 14px;
}
.error-page__description__title__error-code {
  display: block;
  font-size: 18px;
  margin-bottom: 7px;
}
.error-page__description__text {
  font-size: 14px;
}
.error-page__help {
  flex: 0 calc(50% - 7px);
  border: 1px solid #dfdfdf;
  font-size: 12px;
  padding: 21px;
}
.error-page__help h2 {
  font-size: 18px;
  text-align: left;
}
@media screen and (max-width: 50em) {
  .error-page__help {
    display: none;
  }
}
.error-page__help a {
  color: #407b32;
  text-decoration: underline;
}
.error-page__help a:hover {
  text-decoration: none;
}
.error-page__help ul {
  margin: 0 !important;
}
.error-page__help ul li {
  list-style-type: square;
}
.error-page__help ul li::marker {
  color: #407b32;
}
.error-page__categories {
  flex: 0 100%;
  margin-bottom: 20px;
  margin-top: 27px;
}
.error-page__categories__others {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
}
.error-page__categories__others__title {
  margin: 14px 0;
  background: #ececec;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
}
.error-page__categories__need-help-button {
  display: inline-flex;
  padding: 4px 14px 4px 4px;
  border-radius: 4px;
  background: #dfdfdf;
  color: black;
  align-self: center;
}
.error-page__categories__need-help-button__icon {
  width: 40px;
  height: 40px;
  flex: 0 40px;
  background: #7a7a7a;
  border-radius: 4px;
  line-height: 40px;
  color: white;
  text-align: center;
  font-size: 27px;
}
.error-page__categories__need-help-button__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 7px;
}
.error-page__categories__need-help-button__content span {
  font-size: 10px;
  display: block;
  margin-bottom: 6px;
}
.error-page__categories__need-help-button:hover {
  text-decoration: none;
  background: #d1cece;
}
.error-page__top-products {
  flex: 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.subcategories {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  overflow-x: auto;
}
.subcategories--item {
  display: flex;
  align-items: center;
  margin-right: 17px;
  flex: 1;
  flex-grow: 0;
  flex-direction: column;
  height: 100%;
}
.subcategories--item:last-of-type {
  margin-right: 0;
}
.subcategories--item span.name {
  display: flex;
  height: 37px;
  align-items: center;
  margin-top: 9px;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  text-align: center;
  line-height: 1.3;
}
.subcategories--item img {
  border-radius: 5px;
}
@media screen and (max-width: 58.6875em) {
  .subcategories {
    padding: 0 15px 15px 15px;
  }
  .subcategories--item img {
    width: 80px;
    aspect-ratio: 80/85.09;
  }
}
@media screen and (max-width: 29.9375em) {
  .subcategories {
    justify-content: flex-start;
  }
}

.box-slider {
  visibility: hidden;
}
.box-slider.is-active {
  visibility: visible;
}
.box-slider .list-products {
  flex-wrap: initial;
  column-gap: 0;
  row-gap: 0;
  justify-content: initial;
  align-items: initial;
}
.box-slider .list-products__item {
  margin-right: 1.5rem;
}
.box-slider .splide__track {
  padding-bottom: 21px;
}
.box-slider .splide__arrow {
  background: none;
}
.box-slider .splide__arrow svg {
  width: 2rem;
  height: 2rem;
  fill: #878787;
}
.box-slider .splide__pagination__page.is-active {
  background: #65BB51;
}

.list-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  row-gap: 10px;
  column-gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-products__item {
  display: flex;
  flex-direction: column;
  width: calc(25% - 7.5px);
  padding: 0 0 14px;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.61, 0.2, 0.48, 1.01);
}
@media screen and (max-width: 50em) {
  .list-products__item {
    width: calc(33.3333333333% - 10px);
  }
}
@media screen and (max-width: 37.5em) {
  .list-products__item {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 25.625em) {
  .list-products__item {
    width: 100%;
  }
}
.list-products__item:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.list-products__item__link {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
}
.list-products__item__link:hover {
  text-decoration: none;
}
.list-products__item__image {
  display: flex;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
}
.list-products__item__image::before {
  content: "";
  padding-top: 150%;
}
.list-products__item__image__element {
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
  width: 100%;
  height: auto;
}
.list-products__item__info {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  flex: 1;
}
.list-products__item__info__title {
  display: block;
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 7px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.37px;
}
.list-products__item__info__title span {
  display: block;
  font-size: 0.875rem;
  text-transform: none;
  opacity: 75%;
  text-align: center;
  margin-top: 7px;
}
.list-products__item__info__prices {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-content: flex-end;
  gap: 10px;
}
.list-products__item__info__prices__price {
  font-size: 1rem;
  margin-bottom: 10px;
}
.list-products__item__info__prices__price--old {
  line-height: 28px;
  color: #8a8a8a;
  text-decoration: line-through;
}
.list-products__item__info__prices__price--main {
  font-size: 1.125rem;
  font-weight: 700;
}
.list-products__item__info__availability {
  margin: 10px 0;
  color: #3ea938;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  letter-spacing: 0.37px;
}
.list-products__item__info__availability__text {
  display: block;
  line-height: 18px;
  font-size: 0.75rem;
  color: #3ea938;
  font-weight: 700;
  text-align: center;
  max-width: 70%;
}
.list-products__item__info__availability__text__date {
  display: block;
  font-size: 0.6875rem;
  font-weight: 400;
  color: #333;
}
.list-products__item__button {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.3s cubic-bezier(0.61, 0.2, 0.48, 1.01);
}
.list-products__item:hover .list-products__item__button {
  opacity: 1;
}

.list-flags {
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-flags__item {
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 1.1;
  text-align: center;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  padding: 6px 7px;
  word-break: break-all;
  letter-spacing: 0.37px;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.splide[unbounded]:has(.splide__arrows) {
  padding-left: 2.7rem;
  padding-right: 2.7rem;
}
.splide__arrow--prev {
  left: 0;
}
.splide__arrow--next {
  right: 0;
}
.splide:not([unbounded]) .splide__arrow--prev {
  left: auto;
  right: calc(100% + 1rem);
}
.splide:not([unbounded]) .splide__arrow--next {
  left: calc(100% + 1rem);
  right: auto;
}
.splide__pagination:not(.splide__pagination--ttb) {
  bottom: auto;
  top: calc(100% + 0.5rem);
}
.splide__pagination__page.is-active {
  background: var(--aui-color-primary);
}